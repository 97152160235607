/* Contact */
.form-container {
	max-width: 400px;
	padding: 2rem 5px;
	margin: 0 auto;
}
.form {
	border-radius: 5px;
	display: flex;
	flex-direction: column;
}
.form label {
	position: relative;
	padding: 20px 0;
}
.form input,
textarea {
	padding: 0.9rem 10px;
	margin-bottom: 2rem;
	background: transparent;
	color: #01010d;
	border: 0;
	border-bottom: 2px solid #d4af37;
	height: 85px;
}
.form textarea {
	grid-column: 1 / 3;
	height: 150px;
	margin: 20px 0;
}
.form button {
	grid-column: 2 / 3;
	border: 0;
	background: #d4af37;
	border-radius: 5px;
	color: #01010d;
	font-size: 1.3rem;
	width: 100%;
	height: 50px;
	letter-spacing: 4px;
	border: none;
	font-family: 'bebas neue';
}
/* Desktop */
@media only screen and (min-width: 960px) {
}

/* Tablets */
@media only screen and (min-width: 767px) {
	.form {
		border-radius: 5px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 25px;
		grid-row-gap: 20px;
	}
	.form textarea {
		grid-column: 1 / 3;
		height: 150px;
	}
}
