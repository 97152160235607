@import url(https://fonts.googleapis.com/css?family=Bebas+Neue|Squada+One|Stardos+Stencil:300,400,500,700&display=swap);
* {
	box-sizing: border-box;
}

/* fonts */

.app-container {
	max-width: 1100px;
	margin: 0 auto;
}

/* Home Page */

.home-bg {
	height: 80vh;
	background-image: url(/static/media/fence.b9dc587c.jpg);
	background-size: cover;
	text-align: center;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.home-bg::before {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: black;
	opacity: 0.8;
}

.home-bg h1,
.home-bg h2 {
	color: #cccccc;
	line-height: 1.3;
	letter-spacing: 0.19;
	border-radius: 5px;
	position: relative;
	z-index: 5;
}

.home-content {
	font-family: 'Bebas Neue', cursive;
	position: relative;
	max-width: 60%;
	margin: 0 auto;
}

.home-content h2 {
	position: relative;
	top: 30px;
	font-size: larger;
}

.button {
	position: relative;
	top: 100px;
	padding: 0.3rem 0.8rem;
	letter-spacing: 1.2px;
	font-size: 20px;
	border-radius: 20px;
	border: none;
	box-shadow: 0px 10px 15 px rgba(1, 1, 13, 0.3);
	background: #d4af37;
	color: white;
}
.home-content button a {
	text-decoration: none;
	color: white;
}

.sub-header {
	color: #cccccc;
	text-align: center;
	padding: 30px 0;
	position: relative;
	letter-spacing: 2px;
}
.sub-header::before {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: #01010d;
	opacity: 0.7;
}
.sub-header h4 {
	font-size: 2rem;
}
.sub-header h4,
.sub-header p {
	z-index: 2;
	position: relative;
}

/* UnderConstruction */
.puppy {
	max-width: 100%;
}

/* Footer */
.footer {
	background: #01010d;
	color: #cccccc;
}

.footer img {
	height: 12%;
	width: 100%;
	padding: 10px 0;
}

.footer a {
	text-decoration: none;
	color: #cccccc;
}
.footer a:hover {
	text-decoration: none;
	color: #d4af37;
	font-weight: 490;
}

.footer-nav {
	margin: 0 auto;
	font-size: 0.8rem;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	margin-bottom: 40px;
	flex-direction: row;
	grid-column-gap: 50px;
	width: 90%;
	max-width: 1100px;
	padding: 0px 3px;
}

.footer-nav section {
	/* height: 300px; */
	display: flex;
	flex-direction: column;
}
.footer-nav section:nth-child(4) {
	align-self: start;
}
.flex-vertical {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.footer-copyright {
	color: #d4af37;
}
/* Desktop */
@media only screen and (min-width: 960px) {
}

/* Tablets */
@media only screen and (min-width: 767px) {
	.footer-nav {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		padding: 31px 3px;
	}
	.footer-nav section:nth-child(1) {
		align-self: flex-start;
	}
	.footer-nav section:nth-child(2) {
		align-self: flex-start;
		grid-column: 1 / 2;
		grid-row: 2 / 3;
	}
	.footer-nav section:nth-child(3) {
		grid-row: 1 / 3;
	}
	.footer-nav section:nth-child(4) {
		justify-self: center;
	}
	.footer-copyright {
		padding-left: 35%;
	}
}

/* Nav */
.header {
	text-align: center;
	background: #e65c00; /* fallback for old browsers */
	background: -webkit-linear-gradient(to top, #d4af37, #01010d); /* Chrome 10-25, Safari 5.1-6 */
	background: -webkit-gradient(
		linear,
		left bottom, left top,
		from(#d4af37),
		to(#01010d)
	);
	background: -webkit-linear-gradient(
		bottom,
		#d4af37,
		#01010d
	);
	background: linear-gradient(
		to top,
		#d4af37,
		#01010d
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.nav-list {
	background: #01010d;
	border-top: 2px solid #cccccc;
	border-bottom: 2px solid #cccccc;
	padding: 8px 0;
	height: 58px;
	position: relative;
}

.nav-btn {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 9px;
	top: 4px;
	width: 45px;
	height: 45px;
	cursor: pointer;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	border: 1.8px solid #cccccc;
}
.nav-btn.closed {
	visibility: visible;
}
.nav-btn.open {
	display: none;
}

.nav-btn-burger {
	width: 30px;
	height: 3px;
	border-radius: 5px;
	background-color: #cccccc;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	box-shadow: 0 1px 3px rgba(212, 175, 55, 0.3);
}

.nav-btn-burger::before,
.nav-btn-burger::after {
	content: '';
	position: absolute;
	width: 30px;
	height: 3px;
	right: 5px;
	border-radius: 5px;
	background-color: #cccccc;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	box-shadow: 0 1px 3px rgba(212, 175, 55, 0.3);
}

.nav-btn-burger::before {
	-webkit-transform: translateY(-12px);
	        transform: translateY(-12px);
}

.nav-btn-burger::after {
	-webkit-transform: translateY(12px);
	        transform: translateY(12px);
}
.nav-list .nav-menu {
	visibility: hidden;
	-webkit-transition: all 0.35s ease;
	transition: all 0.35s ease;
}

.nav-list .nav-menu.open {
	position: absolute;
	z-index: 19;
	width: 75vw;
	top: -2px;
	right: 0;
	background: #01010d;
	border-top: 2px solid #cccccc;
	border-bottom: 2px solid #cccccc;
	visibility: visible;
}
.nav-list .nav-menu.open ul {
	height: 100%;
}
.nav-list .nav-menu.open ul li {
	padding: 15px 0;
}
.nav-list .nav-menu.closed {
	display: none;
}
.nav-list .nav-menu .x {
	text-align: left;
	padding-left: 23px;
	font-size: 1.2rem;
	color: white;
	font-weight: 700;
	padding-top: 8px;
	cursor: pointer;
}
.nav-list ul {
	display: flex;
	flex-direction: column;
	align-content: center;
	padding: 8px 0;
	height: 90%;
	list-style: none;
}

.nav-list li :hover {
	color: #d4af37;
	opacity: 0.5;
}

.nav-list li a {
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 1px;
	color: #cccccc;
}

.logo {
	position: relative;
	top: 10px;
	max-width: 9rem;
	height: auto;
	padding-bottom: 20px;
}

.logo-text {
	font-family: 'Squada One', cursive;
	font-size: 2rem;
	color: #2a3d45;
	padding-left: 30%;
	padding-top: 2.5rem;
}
/* Desktop */
@media only screen and (min-width: 960px) {
	.nav-btn.closed {
		display: none;
	}
	.nav-list .nav-menu {
		visibility: visible;
	}
	.nav-list .nav-menu.closed {
		display: flex;
	}
	.nav-list .nav-menu .x {
		display: none;
	}
	.nav-list ul {
		display: flex;
		width: 100%;
		max-width: 1100px;
		margin: 0 auto;
		flex-direction: row;
		justify-content: space-evenly;
		padding: 8px 0;
		list-style: none;
	}
	.nav-list li a {
		letter-spacing: 0.5px;
	}
}

/* Tablets */
@media only screen and (min-width: 767px) {
}

/* About Us */

.sub-header {
	background: #01010d;
	color: #cccccc;
	text-align: center;
	padding: 30px 0;
	letter-spacing: 2px;
}
.card-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 12px;
}
.team-view {
	justify-content: space-evenly;
	padding-top: 200px;
}
.card-container {
	background-color: #ebeef8;
	margin: 2rem 0;
	box-shadow: 0.5rem 0.5rem 3rem rgba(0, 0, 0, 0.2);
	border-bottom: 4px solid #d4af37;
	border-radius: 5px;
}
.card-container .card-img {
	width: 100%;
	height: 16rem;
	object-fit: cover;
	-webkit-clip-path: polygon(30% 0%, 70% 0%, 99% 0, 100% 74%, 80% 86%, 22% 86%, 0 75%, 0 0);
	        clip-path: polygon(30% 0%, 70% 0%, 99% 0, 100% 74%, 80% 86%, 22% 86%, 0 75%, 0 0);
}
.card-container .overlay-bg .management-name {
	display: none;
}
.management-img {
	text-align: center;
}
.management-img img {
	width: 11rem;
	height: 11rem;
	object-fit: cover;
	border-radius: 50%;
	margin-top: -10rem;
	z-index: 9;
	position: relative;
	border: 0.7rem solid #ebeef8;
}
.management-info {
	text-align: center;
	padding-top: 12px;
}
.management-name {
	color: #d4af37;
	font-weight: 700;
	font-size: 2rem;
	width: 90%;
	margin: 1.2rem auto;
	text-transform: uppercase;
}
.management-title {
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 400;
	margin-top: -16px;
}
.card-container p {
	color: black;
	font-size: 0.8rem;
	padding: 0 1.5rem;
	margin: 1.5rem 0;
	line-height: 1.8;
	text-align: left;
}
#testimonial-section {
}
#testimonial-section h2 {
	background: #01010d;
	color: #cccccc;
	letter-spacing: 1px;
	text-align: center;
	text-transform: uppercase;
	font-size: 1.75rem;
	padding: 40px 0px;
}
#testimonial-section .testimonial-bubble {
}
.team-background #testimonial-section .testimonial-bubble {
	background: #ebeef8;
	height: 500px;
	width: 100%;
	margin: 30px auto;
	padding: 20px 10px 0px 10px;
	-webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
	        clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
}
blockquote {
	font-family: Georgia, serif;
	position: relative;
	line-height: 1.8;
	margin: 0.5em;
	overflow: hidden;
	padding: 0.5em 1em 0.5em 1em;
}
/* Thanks: http://callmenick.com/post/styling-blockquotes-with-css-pseudo-classes */
blockquote:before {
	font-family: Georgia, serif;
	position: absolute;
	font-size: 4em;
	line-height: 1;
	color: #d4af37;
	top: 1px;
	z-index: -1;
	opacity: 0.3;
	left: -5px;
	content: '\201C';
}
blockquote:after {
	font-family: Georgia, serif;
	position: absolute;
	/* display: block; don't use this, it raised the quote too high from the bottom - defeated line-height? */
	float: right;
	z-index: -1;
	font-size: 19em;
	line-height: 1;
	color: #d4af37;
	right: -23px;
	opacity: 0.3;
	bottom: -0.5em;
	content: '\201D';
}
blockquote footer {
	padding-top: 2em;
}
blockquote cite:before {
	content: '\2013';
}
/* Desktop */
@media only screen and (min-width: 960px) {
	.team-container {
		padding: 20px 0;
	}
	.card-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 10px;
	}
	.card-container {
		position: relative;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		height: 450px;
		cursor: pointer;
	}
	.card-container p {
		display: none;
		position: absolute;
		top: 37%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
	}
	.card-container .overlay-bg {
		position: absolute;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		opacity: 0;
		background-color: #ebeef8;
		height: 0;
		width: 100%;
		bottom: 0;
		left: 0;
	}
	.card-container:hover .management-img {
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		-webkit-transform: scale(0.5) translateY(-365px);
		        transform: scale(0.5) translateY(-365px);
		z-index: 60;
	}
	.card-container:hover .overlay-bg {
		height: 80%;
		opacity: 1;
	}
	.card-container:hover .overlay-bg h1 {
		display: block;
		text-align: center;
		width: 100%;
		position: relative;
		font-size: 1.7rem;
		margin-bottom: 0.2rem;
	}
	.card-container:hover .overlay-bg p {
		color: black;
		font-size: 0.8rem;
		padding: 0 1.5rem;
		margin: 1.5rem 0;
		line-height: 1.8;
		text-align: left;
		width: 100%;
		position: relative;
		display: block;
	}
	.card-container:hover .overlay-bg p:nth-child(1) {
		font-size: 0.6rem;
	}
	.team-background #testimonial-section .testimonial-bubble {
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
	}
	#testimonial-section .testimonial-bubble .testimonial-quote {
		padding-top: 0px !important;
		position: relative;
		top: -16%;
	}
}

/* Tablets */
@media only screen and (min-width: 767px) {
	#testimonial-section .testimonial-bubble .testimonial-quote {
		padding-top: 4rem;
	}
}

/* Contact */
.form-container {
	max-width: 400px;
	padding: 2rem 5px;
	margin: 0 auto;
}
.form {
	border-radius: 5px;
	display: flex;
	flex-direction: column;
}
.form label {
	position: relative;
	padding: 20px 0;
}
.form input,
textarea {
	padding: 0.9rem 10px;
	margin-bottom: 2rem;
	background: transparent;
	color: #01010d;
	border: 0;
	border-bottom: 2px solid #d4af37;
	height: 85px;
}
.form textarea {
	grid-column: 1 / 3;
	height: 150px;
	margin: 20px 0;
}
.form button {
	grid-column: 2 / 3;
	border: 0;
	background: #d4af37;
	border-radius: 5px;
	color: #01010d;
	font-size: 1.3rem;
	width: 100%;
	height: 50px;
	letter-spacing: 4px;
	border: none;
	font-family: 'bebas neue';
}
/* Desktop */
@media only screen and (min-width: 960px) {
}

/* Tablets */
@media only screen and (min-width: 767px) {
	.form {
		border-radius: 5px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 25px;
		grid-row-gap: 20px;
	}
	.form textarea {
		grid-column: 1 / 3;
		height: 150px;
	}
}

/* Services */
.services-header-bg {
	background-image: url(/static/media/many-cameras.d5b910a3.jpg), -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 255, 0.1)));
	background-image: url(/static/media/many-cameras.d5b910a3.jpg), -webkit-linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 255, 0.1));
	background-image: url(/static/media/many-cameras.d5b910a3.jpg), linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 255, 0.1));
	position: relative;
}
.overlay {
	position: absolute;
	top: 0;
	left: 0;
	background: radial-linear-gradient(rgba(0, 0, 0, 0.5));
}
.services-header-bg:nth-child(1) {
	position: relative;
	z-index: 3;
}
.services-header-bg:nth-child(2) {
	position: relative;
	z-index: 3;
}
.service-container {
	position: relative;
	top: -30px;
	z-index: 3;
}
.service-header {
	font-size: 24px;
}
.service-description {
	font-size: 1rem;
	font-weight: 300;
}
/* Desktop */
@media only screen and (min-width: 960px) {
}

/* Tablets */
@media only screen and (min-width: 767px) {
	.service-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 8px;
	}
	.card:nth-child(5) {
		grid-column: 1 / 3;
	}
}

.course-section {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
		'Helvetica Neue', sans-serif;
}
.hero-section {
	display: flex;
	position: relative;
	background-color: #f7f7f7;
	background-size: cover;
	background-position-x: 106px;
	height: 250px;
	background-image: url(/static/media/securityguard.4693e137.png), -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 255, 0.1)));
	background-image: url(/static/media/securityguard.4693e137.png), -webkit-linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 255, 0.1));
	background-image: url(/static/media/securityguard.4693e137.png), linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 255, 0.1));
}
.hero-section::before {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: black;
	opacity: 0.1;
}
.hero-section div {
	width: 60%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
	position: relative;
}
.hero-section div h3 {
	padding: 10px;
	-webkit-font-feature-settings: "c2sc";
	        font-feature-settings: "c2sc";
	font-variant: small-caps;
	font-size: 1.2rem;
	line-height: 1.45;
}
#D {
	color: #d4af37;
	font-weight: 700;
}

.course-cta {
	background-color: #01010d;
	color: #cccccc;
	padding: 5%;
	position: relative;
}

.course-cta h3 {
	font-size: 1.2rem;
	color: whitesmoke;
	-webkit-font-feature-settings: "c2sc";
	        font-feature-settings: "c2sc";
	font-variant: small-caps;
}

.course-cta hr {
	border: 1px solid #d4af37;
	width: 49%;
	position: absolute;
	left: 9%;
	top: 22%;
}

.course-cta p {
	width: 85%;
	line-height: 1.7;
	padding-top: 12%;
	font-weight: 300;
}

.cta-button {
	padding: 10px 0;
	margin-top: 5%;
	color: #01010d;
	width: 45%;
	background: #d4af37;
	border-radius: 5px;
	border: 1px solid #d4af37;
}
.course-desc {
	border: 1px solid #01010d;
	margin: 5px 0;
	padding: 5%;
	-webkit-font-feature-settings: "c2sc";
	        font-feature-settings: "c2sc";
	font-variant: small-caps;
}
.course-desc h3 {
	padding-top: 9%;
	font-size: 1.2rem;
	border-bottom: 2px solid #d4af37;
	width: 59%;
	padding-bottom: 2%;
}
.course-desc p {
	width: 85%;
	line-height: 1.7;
	padding-top: 6%;
	font-weight: 300;
}

.course-desc li {
	margin-bottom: 4%;
}

.course-desc span {
	color: #d4af37;
	font-weight: 600;
}

.course-contact {
	background-color: #01010d;
	color: #cccccc;
	padding: 10% 5%;
	text-align: center;
	margin-bottom: 1px;
	position: relative;
	overflow: hidden;
	z-index: 22;
}

.course-contact::before {
	content: '';
	width: 50%;
	height: 50%;
	opacity: 0.6;
	position: absolute;
	z-index: -1;
	top: 0px;
	right: 0px;
	background: #d4af37;
	background: -webkit-gradient(linear, left top, left bottom, from(#d4af37), color-stop(90%, #01010d));
	background: -webkit-linear-gradient(top, #d4af37 0%, #01010d 90%);
	background: linear-gradient(180deg, #d4af37 0%, #01010d 90%);
	background: -webkit-linear-gradient(180deg, #d4af37 0%, #01010d 90%);
	background: -moz-linear-gradient(180deg, #d4af37 0%, #01010d 90%);
	-webkit-clip-path: circle(30.8% at 100% 50%);
	        clip-path: circle(30.8% at 100% 50%);
}
.course-contact::after {
	content: '';
	width: 50%;
	height: 50%;
	position: absolute;
	bottom: -21%;
	left: -28%;
	opacity: 0.3;
	background: #d4af37;
	background: -webkit-gradient(linear, left top, left bottom, from(#d4af37), color-stop(90%, #01010d));
	background: -webkit-linear-gradient(top, #d4af37 0%, #01010d 90%);
	background: linear-gradient(180deg, #d4af37 0%, #01010d 90%);
	background: -webkit-linear-gradient(180deg, #d4af37 0%, #01010d 90%);
	background: -moz-linear-gradient(180deg, #d4af37 0%, #01010d 90%);
	-webkit-clip-path: polygon(0% 0%, 0% 100%, 4% 100%, 5% 5%, 95% 5%, 94% 95%, 7% 95%, 4% 100%, 100% 100%, 100% 0%);
	        clip-path: polygon(0% 0%, 0% 100%, 4% 100%, 5% 5%, 95% 5%, 94% 95%, 7% 95%, 4% 100%, 100% 100%, 100% 0%);
	z-index: -1;
}

.course-contact h3,
button {
	font-size: 1rem;
	-webkit-font-feature-settings: "c2sc";
	        font-feature-settings: "c2sc";
	font-variant: small-caps;
	color: #cccccc;
}

.course-contact a {
	text-decoration: none;
	color: #d4af37;
}
.course-contact .cta-button a {
	text-decoration: none;
	color: #01010d;
}
/* Desktop */
@media only screen and (min-width: 960px) {
}

/* Tablets */
@media only screen and (min-width: 767px) {
	.hero-section {
		display: none !important;
	}
	.course-cta {
		background-image: url(/static/media/securityguard2.a3e492b3.png),
			-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 255, 0.1)));
		background-image: url(/static/media/securityguard2.a3e492b3.png),
			-webkit-linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 255, 0.1));
		background-image: url(/static/media/securityguard2.a3e492b3.png),
			linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 255, 0.1));
		position: relative;
		background-position-x: 22rem;
		background-size: cover;
	}
	.course-cta::before {
		content: '';
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: black;
		opacity: 0.8;
	}
	.course-cta .cta-hero {
		z-index: 5;
		position: relative;
		width: 75%;
		color: white;
	}
	.course-cta .cta-hero h3 {
		font-size: 3rem;
	}
	.course-cta .cta-hero hr {
		top: 29%;
	}
	.course-cta .cta-hero p {
		width: 75%;
		line-height: 1.7;
		padding-top: 5%;
		font-weight: 300;
	}
	.cta-button {
		color: white;
		font-weight: 400;
	}
	.course-desc p {
		line-height: 2.7;
	}
}

