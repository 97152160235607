* {
	box-sizing: border-box;
}

/* fonts */
@import url('https://fonts.googleapis.com/css?family=Bebas+Neue|Squada+One|Stardos+Stencil:300,400,500,700&display=swap');

.app-container {
	max-width: 1100px;
	margin: 0 auto;
}

/* Home Page */

.home-bg {
	height: 80vh;
	background-image: url('./images/fence.jpg');
	background-size: cover;
	text-align: center;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.home-bg::before {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: black;
	opacity: 0.8;
}

.home-bg h1,
.home-bg h2 {
	color: #cccccc;
	line-height: 1.3;
	letter-spacing: 0.19;
	border-radius: 5px;
	position: relative;
	z-index: 5;
}

.home-content {
	font-family: 'Bebas Neue', cursive;
	position: relative;
	max-width: 60%;
	margin: 0 auto;
}

.home-content h2 {
	position: relative;
	top: 30px;
	font-size: larger;
}

.button {
	position: relative;
	top: 100px;
	padding: 0.3rem 0.8rem;
	letter-spacing: 1.2px;
	font-size: 20px;
	border-radius: 20px;
	border: none;
	box-shadow: 0px 10px 15 px rgba(1, 1, 13, 0.3);
	background: #d4af37;
	color: white;
}
.home-content button a {
	text-decoration: none;
	color: white;
}

.sub-header {
	color: #cccccc;
	text-align: center;
	padding: 30px 0;
	position: relative;
	letter-spacing: 2px;
}
.sub-header::before {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: #01010d;
	opacity: 0.7;
}
.sub-header h4 {
	font-size: 2rem;
}
.sub-header h4,
.sub-header p {
	z-index: 2;
	position: relative;
}

/* UnderConstruction */
.puppy {
	max-width: 100%;
}

/* Footer */
.footer {
	background: #01010d;
	color: #cccccc;
}

.footer img {
	height: 12%;
	width: 100%;
	padding: 10px 0;
}

.footer a {
	text-decoration: none;
	color: #cccccc;
}
.footer a:hover {
	text-decoration: none;
	color: #d4af37;
	font-weight: 490;
}

.footer-nav {
	margin: 0 auto;
	font-size: 0.8rem;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	margin-bottom: 40px;
	flex-direction: row;
	grid-column-gap: 50px;
	width: 90%;
	max-width: 1100px;
	padding: 0px 3px;
}

.footer-nav section {
	/* height: 300px; */
	display: flex;
	flex-direction: column;
}
.footer-nav section:nth-child(4) {
	align-self: start;
}
.flex-vertical {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.footer-copyright {
	color: #d4af37;
}
/* Desktop */
@media only screen and (min-width: 960px) {
}

/* Tablets */
@media only screen and (min-width: 767px) {
	.footer-nav {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		padding: 31px 3px;
	}
	.footer-nav section:nth-child(1) {
		align-self: flex-start;
	}
	.footer-nav section:nth-child(2) {
		align-self: flex-start;
		grid-column: 1 / 2;
		grid-row: 2 / 3;
	}
	.footer-nav section:nth-child(3) {
		grid-row: 1 / 3;
	}
	.footer-nav section:nth-child(4) {
		justify-self: center;
	}
	.footer-copyright {
		padding-left: 35%;
	}
}
