/* About Us */

.sub-header {
	background: #01010d;
	color: #cccccc;
	text-align: center;
	padding: 30px 0;
	letter-spacing: 2px;
}
.card-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 12px;
}
.team-view {
	justify-content: space-evenly;
	padding-top: 200px;
}
.card-container {
	background-color: #ebeef8;
	margin: 2rem 0;
	box-shadow: 0.5rem 0.5rem 3rem rgba(0, 0, 0, 0.2);
	border-bottom: 4px solid #d4af37;
	border-radius: 5px;
}
.card-container .card-img {
	width: 100%;
	height: 16rem;
	object-fit: cover;
	clip-path: polygon(30% 0%, 70% 0%, 99% 0, 100% 74%, 80% 86%, 22% 86%, 0 75%, 0 0);
}
.card-container .overlay-bg .management-name {
	display: none;
}
.management-img {
	text-align: center;
}
.management-img img {
	width: 11rem;
	height: 11rem;
	object-fit: cover;
	border-radius: 50%;
	margin-top: -10rem;
	z-index: 9;
	position: relative;
	border: 0.7rem solid #ebeef8;
}
.management-info {
	text-align: center;
	padding-top: 12px;
}
.management-name {
	color: #d4af37;
	font-weight: 700;
	font-size: 2rem;
	width: 90%;
	margin: 1.2rem auto;
	text-transform: uppercase;
}
.management-title {
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 400;
	margin-top: -16px;
}
.card-container p {
	color: black;
	font-size: 0.8rem;
	padding: 0 1.5rem;
	margin: 1.5rem 0;
	line-height: 1.8;
	text-align: left;
}
#testimonial-section {
}
#testimonial-section h2 {
	background: #01010d;
	color: #cccccc;
	letter-spacing: 1px;
	text-align: center;
	text-transform: uppercase;
	font-size: 1.75rem;
	padding: 40px 0px;
}
#testimonial-section .testimonial-bubble {
}
.team-background #testimonial-section .testimonial-bubble {
	background: #ebeef8;
	height: 500px;
	width: 100%;
	margin: 30px auto;
	padding: 20px 10px 0px 10px;
	clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
}
blockquote {
	font-family: Georgia, serif;
	position: relative;
	line-height: 1.8;
	margin: 0.5em;
	overflow: hidden;
	padding: 0.5em 1em 0.5em 1em;
}
/* Thanks: http://callmenick.com/post/styling-blockquotes-with-css-pseudo-classes */
blockquote:before {
	font-family: Georgia, serif;
	position: absolute;
	font-size: 4em;
	line-height: 1;
	color: #d4af37;
	top: 1px;
	z-index: -1;
	opacity: 0.3;
	left: -5px;
	content: '\201C';
}
blockquote:after {
	font-family: Georgia, serif;
	position: absolute;
	/* display: block; don't use this, it raised the quote too high from the bottom - defeated line-height? */
	float: right;
	z-index: -1;
	font-size: 19em;
	line-height: 1;
	color: #d4af37;
	right: -23px;
	opacity: 0.3;
	bottom: -0.5em;
	content: '\201D';
}
blockquote footer {
	padding-top: 2em;
}
blockquote cite:before {
	content: '\2013';
}
/* Desktop */
@media only screen and (min-width: 960px) {
	.team-container {
		padding: 20px 0;
	}
	.card-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 10px;
	}
	.card-container {
		position: relative;
		transition: all 0.3s ease-in-out;
		height: 450px;
		cursor: pointer;
	}
	.card-container p {
		display: none;
		position: absolute;
		top: 37%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.card-container .overlay-bg {
		position: absolute;
		transition: all 0.3s ease-in-out;
		opacity: 0;
		background-color: #ebeef8;
		height: 0;
		width: 100%;
		bottom: 0;
		left: 0;
	}
	.card-container:hover .management-img {
		transition: all 0.3s ease-in-out;
		transform: scale(0.5) translateY(-365px);
		z-index: 60;
	}
	.card-container:hover .overlay-bg {
		height: 80%;
		opacity: 1;
	}
	.card-container:hover .overlay-bg h1 {
		display: block;
		text-align: center;
		width: 100%;
		position: relative;
		font-size: 1.7rem;
		margin-bottom: 0.2rem;
	}
	.card-container:hover .overlay-bg p {
		color: black;
		font-size: 0.8rem;
		padding: 0 1.5rem;
		margin: 1.5rem 0;
		line-height: 1.8;
		text-align: left;
		width: 100%;
		position: relative;
		display: block;
	}
	.card-container:hover .overlay-bg p:nth-child(1) {
		font-size: 0.6rem;
	}
	.team-background #testimonial-section .testimonial-bubble {
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
	}
	#testimonial-section .testimonial-bubble .testimonial-quote {
		padding-top: 0px !important;
		position: relative;
		top: -16%;
	}
}

/* Tablets */
@media only screen and (min-width: 767px) {
	#testimonial-section .testimonial-bubble .testimonial-quote {
		padding-top: 4rem;
	}
}
