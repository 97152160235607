/* Nav */
.header {
	text-align: center;
	background: #e65c00; /* fallback for old browsers */
	background: -webkit-linear-gradient(to top, #d4af37, #01010d); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to top,
		#d4af37,
		#01010d
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.nav-list {
	background: #01010d;
	border-top: 2px solid #cccccc;
	border-bottom: 2px solid #cccccc;
	padding: 8px 0;
	height: 58px;
	position: relative;
}

.nav-btn {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 9px;
	top: 4px;
	width: 45px;
	height: 45px;
	cursor: pointer;
	transition: all 0.5s ease-in-out;
	border: 1.8px solid #cccccc;
}
.nav-btn.closed {
	visibility: visible;
}
.nav-btn.open {
	display: none;
}

.nav-btn-burger {
	width: 30px;
	height: 3px;
	border-radius: 5px;
	background-color: #cccccc;
	transition: all 0.5s ease-in-out;
	box-shadow: 0 1px 3px rgba(212, 175, 55, 0.3);
}

.nav-btn-burger::before,
.nav-btn-burger::after {
	content: '';
	position: absolute;
	width: 30px;
	height: 3px;
	right: 5px;
	border-radius: 5px;
	background-color: #cccccc;
	transition: all 0.5s ease-in-out;
	box-shadow: 0 1px 3px rgba(212, 175, 55, 0.3);
}

.nav-btn-burger::before {
	transform: translateY(-12px);
}

.nav-btn-burger::after {
	transform: translateY(12px);
}
.nav-list .nav-menu {
	visibility: hidden;
	transition: all 0.35s ease;
}

.nav-list .nav-menu.open {
	position: absolute;
	z-index: 19;
	width: 75vw;
	top: -2px;
	right: 0;
	background: #01010d;
	border-top: 2px solid #cccccc;
	border-bottom: 2px solid #cccccc;
	visibility: visible;
}
.nav-list .nav-menu.open ul {
	height: 100%;
}
.nav-list .nav-menu.open ul li {
	padding: 15px 0;
}
.nav-list .nav-menu.closed {
	display: none;
}
.nav-list .nav-menu .x {
	text-align: left;
	padding-left: 23px;
	font-size: 1.2rem;
	color: white;
	font-weight: 700;
	padding-top: 8px;
	cursor: pointer;
}
.nav-list ul {
	display: flex;
	flex-direction: column;
	align-content: center;
	padding: 8px 0;
	height: 90%;
	list-style: none;
}

.nav-list li :hover {
	color: #d4af37;
	opacity: 0.5;
}

.nav-list li a {
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 1px;
	color: #cccccc;
}

.logo {
	position: relative;
	top: 10px;
	max-width: 9rem;
	height: auto;
	padding-bottom: 20px;
}

.logo-text {
	font-family: 'Squada One', cursive;
	font-size: 2rem;
	color: #2a3d45;
	padding-left: 30%;
	padding-top: 2.5rem;
}
/* Desktop */
@media only screen and (min-width: 960px) {
	.nav-btn.closed {
		display: none;
	}
	.nav-list .nav-menu {
		visibility: visible;
	}
	.nav-list .nav-menu.closed {
		display: flex;
	}
	.nav-list .nav-menu .x {
		display: none;
	}
	.nav-list ul {
		display: flex;
		width: 100%;
		max-width: 1100px;
		margin: 0 auto;
		flex-direction: row;
		justify-content: space-evenly;
		padding: 8px 0;
		list-style: none;
	}
	.nav-list li a {
		letter-spacing: 0.5px;
	}
}

/* Tablets */
@media only screen and (min-width: 767px) {
}
